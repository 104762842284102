<template>
  <div class="login">
      <canvas></canvas>
      <div class="login-box">
        <div class="login-title">
            小熊管理后台
        </div>
        <div class="login-from-box">
            <el-input v-model="username" placeholder="用户名"></el-input>
        </div>
        <div class="login-from-box">
            <el-input type="password" v-model="password" placeholder="密码" @keyup.enter.native="login"></el-input>
        </div>
        <div class="login-btn" @click="login">
            登录
        </div>
      </div>
  </div>
</template>
<script>
</script>
<script>
    import {
        md5
    } from '@/config/md5'
    import {
        canvesLogin
    } from '@/assets/js/canvas.js'
    import * as api from '@/config/api'
    export default {
        data() {
            return {
                username: '',
                password: ''
            }
        },
        mounted() {
            canvesLogin()
        },
        methods: {
            login() {
                if (this.username == '' || this.password == '') {
                    this.$message.error('请输入正确的账号或密码');
                    return
                }
                api.getLogin({
                    'user_name': this.username,
                    'password': md5(this.password)
                }, res => {
                    let store = {
                        'userName': res.data.real_name,
                        'userToken': res.data.token,
                        'userId': res.data.admin_id
                    }
                    window.localStorage.setItem('messageStore', JSON.stringify(store))
                    this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem("messageStore"))))

                    if (location.origin + location.pathname != document.referrer && document.referrer != '') {
                        location.href = document.referrer + '?userName=' + res.data.real_name + '&userId=' + res.data.admin_id + '&userToken=' + res.data.token
                        return
                    }
                    api.getCommon()
                    if (res.code === 2019) {
                        this.$router.push({
                            path: '/modifyPwd'
                        })
                    } else {
                        this.$router.push({
                            path: '/'
                        })
                    }
                }, err => {
                    alert(err.message)
                })
            },
        }
    }
</script>
<style>
    .login {
        background-color: #343a40
    }
    
    .login-box {
        width: 400px;
        height: 300px;
        background-color: rgba(234, 234, 234, .5);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    }
    
    .login-title {
        font-size: 40px;
        margin-top: 30px;
        -webkit-text-stroke: 1px #ffffff;
        color: transparent;
    }
    
    .login-from-box {
        width: 300px;
        margin: 20px auto;
    }
    
    .login-btn {
        width: 180px;
        height: 30px;
        font-size: 20px;
        font-weight: 400;
        margin: 0 auto;
        color: #fff;
        border: 0;
        border-radius: 15px;
        cursor: pointer;
        /* background-image: linear-gradient(to right, #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%, #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%) */
    }
    
    .login-title:hover {
        color: #fff;
        -webkit-animation: Glow 1.5s ease infinite alternate;
        animation: Glow 1.5s ease infinite alternate;
    }
</style>